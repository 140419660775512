import { flatten } from 'ramda'
import React, { FC, ReactElement } from 'react'
import { MediaTypes, useMediaOnly } from '../hooks'
import { ElvisIcon } from '../components/ElvisIcon'
import classNames from 'classnames'

const RestructureTableData: FC<{ children: JSX.Element[] }> = ({
  children,
}) => {
  const isMobileOnly = useMediaOnly(MediaTypes.MOBILE)
  if (!isMobileOnly) {
    return children
  }

  const head = (children[0] as ReactElement).props?.children?.props?.children
  const body = flatten((children[1] as ReactElement).props?.children)
  const modifiedBody = body.map((bodyRow: ReactElement | false) => {
    return (
      bodyRow &&
      bodyRow.props.children.map((cell: ReactElement, cellIndex: number) => {
        if (bodyRow.key === 'expandableContent') {
          return (
            <tr key={cellIndex}>
              {cell.props.children[0]}
              {cell.props.children[cell.props.children.length - 1]}
            </tr>
          )
        }
        if (bodyRow.key === 'expandableButton') {
          return <tr key={cellIndex}>{cell}</tr>
        }
        if (cellIndex === 0) {
          return (
            <tr
              className={classNames(
                'e-table-mobile__cell',
                bodyRow.props.role === 'link' && 'e-table-mobile__select'
              )}
              key={cellIndex}
            >
              <th colSpan={bodyRow.props.role === 'link' ? 1 : 2}>
                {cell.props.children}
              </th>
              {bodyRow.props.role === 'link' && (
                <th className="e-table-mobile__cell" scope="col">
                  <div className="e-link e-link--action">
                    <span className="e-link__icon">
                      <ElvisIcon iconName="arrowRightCircle" />
                      <ElvisIcon iconName="arrowRightCircleFilledColor" />
                    </span>
                  </div>
                </th>
              )}
            </tr>
          )
        }
        return cell.props.children && cell.props.className !== 'link-column' ? (
          <tr key={cellIndex}>
            <td>{head[cellIndex].props.children}</td>
            {cell}
          </tr>
        ) : null
      })
    )
  })

  return (
    <>
      <thead>
        <tr>
          <th colSpan={2}>{head[0].props.children}</th>
        </tr>
      </thead>
      <tbody>{modifiedBody}</tbody>
    </>
  )
}

export default RestructureTableData
