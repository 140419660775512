import React, { FC } from 'react'

import {
  Dropdown as ElvisDropdown,
  DropdownProps,
  DropdownValue,
  DropdownItem,
} from '@elvia/elvis-dropdown/react'

export type { DropdownProps, DropdownValue, DropdownItem }

export const Dropdown: FC<DropdownProps & React.RefAttributes<HTMLElement>> = ({
  ...rest
}) => {
  return <ElvisDropdown {...rest} />
}
